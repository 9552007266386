import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, NavigationEnd } from "@angular/router";
import { Subscription } from 'rxjs';
import { AuthenticationService } from './core/authentication/authentication.service';
import { LoaderService } from './core/services';
import { environment } from 'src/environments/environment';
// import { ToasterConfig } from 'ngx-toastr';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  // public config: ToasterConfig = new ToasterConfig({
  //   animation: 'fade',
  //   positionClass: 'toast-top-full-width',
  //   showCloseButton: true,
  //   timeout: 8000
  // });

  public interval: any;

  private subscription: Subscription = new Subscription();
  public encryptedData:any;
  public currentPath:any;
  public csrfToken:any;
  constructor(private authenticationService: AuthenticationService, private router: Router, private loaderService: LoaderService) {
    // this.subscription.add(router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationStart) {
    //     browserRefresh = !router.navigated;
    //   }
    // }));
    // sessionStorage.removeItem("csrfToken")
    this.encryptedData = new URLSearchParams(window.location.search).get('csrfToken');
    this.csrfToken = new URLSearchParams(window.location.search).get('id');
    console.log("localStorage.getItem",this.csrfToken)
    if(this.csrfToken){
      sessionStorage.setItem("csrfToken",this.csrfToken)
    }
    // if()
     let token =  sessionStorage.getItem("csrfToken")  
    console.log("localStorage.getItem",localStorage.getItem("csrfToken"))
    this.subscription.add(
      router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentPath = this.router.url;
          var updatedURL = this.router.url.replace(/[?&]id=([^&#]*)/, '');
          // if(token == "null"){
          //   token = null
          // }
          if(!this.encryptedData && !token && updatedURL != '/auth/admin/reset-password'){
            window.location.href = environment.alarmPermitHost
          }
          // browserRefresh = !router.navigated;
        }
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(this.authenticationService.obsLoginDetails.subscribe((data: any) => {
      if (data) {
        // this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        //   sessionStorage.setItem('csrfToken', data);
        // }));
        this.intervalFunction();
      } else {
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          const currentApplicantUser = user ? user : null;
          // if (currentApplicantUser && currentApplicantUser.isUser) {
          //   this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
          //     sessionStorage.setItem('csrfToken', data);
          //   }));
          //   this.intervalFunction();
          // }
        }));
      }
    }));
    this.subscription.add(this.authenticationService.obsLoggedOutBs.subscribe((data: any) => {
      if (data) {
        clearInterval(this.interval);
      }
    }))
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 7000)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  intervalFunction() {
    this.interval = setInterval(() => {
      // this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
      //   sessionStorage.setItem('csrfToken', data);
      // }));
    }, 50000);
  }

}
