import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { UsersService } from '../users';
const credentialsKey = 'currentUser'

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public currentApplication = new BehaviorSubject(null);
  public selectedStaff = new BehaviorSubject(null)

  constructor(private http: HttpClient, private toasterService: ToastrService,public userService:UsersService,) { }

  setCurrentApplication(application) {
    this.currentApplication.next(application);
  }

  getCurrentApplication(): Observable<any> {
    return this.currentApplication.asObservable();
  }


  setCurrentStaff(data) {
    sessionStorage.setItem('selectedStaff', JSON.stringify(data));
  }

  getCurrentStaff() {
    return sessionStorage.getItem('selectedStaff') ? (JSON.parse(sessionStorage.getItem('selectedStaff')) || null) : null
  }

  removeCurrentStaff() {
    sessionStorage.removeItem('selectedStaff');
  }

  getApplicationList(pagination): Observable<any> {
    const href = environment.getAdminApplication;
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  saveAddressCSVFile(payload): Observable<any> {
    const href = environment['saveAddress'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getCityAdminList(pagination): Observable<any> {
    const href = environment['getCityAdminList'];
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  resendStaffLink(id): Observable<any> {
    const href = environment['resendStaffLink'];
    return this.http.post<any>(href, id).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Link Sent Successfully");
        return data.response;
      }
    }));
  }

  addCityAdmin(toSend): Observable<any> {
    const href = environment['addCityAdmin'];
    return this.http.post<any>(href, toSend).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Added Successfully");
        return data;
      }
    }));
  }

  updateCityAdmin(payload): Observable<any> {
    const href = environment['updateCityAdmin'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  getCityAdmin(id): Observable<any> {
    const href = `${environment.getCityAdminById}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getAdminStaff(pagination): Observable<any> {
    const href = environment['getCityAdminStaffList'];
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  addStaff(toSend): Observable<any> {
    const href = environment['addStaffByCityAdmin'];
    return this.http.post<any>(href, toSend).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getApplicationById(id): Observable<any> {
    const href = environment['adminApplicationById'] + id;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  updateApplicationByAdmin(payload): Observable<any> {
    const href = environment['applicationUpdateByAdmin'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  postSubmissionReview(payload): Observable<any> {
    const href = environment['postSubmissionReview'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  voidSubmissionReview(payload): Observable<any> {
    const href = environment['voidSubmissionReview'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  postDecision(payload): Observable<any> {
    const href = environment['postDecision'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  voidDecision(payload): Observable<any> {
    const href = environment['voidDecision'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  submitNote(payload): Observable<any> {
    const href = environment['submitNotes'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  postEmail(payload): Observable<any> {
    const href = environment['postEmail'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  deleteNote(payload): Observable<any> {
    const href = environment['deleteNote'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Deleted successfully");
        return data.response;
      }
    }));
  }

  postPayment(payload): Observable<any> {
    const href = environment['postPayment'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Submitted successfully");
        return data.response;
      }
    }));
  }

  feeWaiveOff(payload): Observable<any> {
    const href = environment['waiveOffFee'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  managerUploadSignature(payload): Observable<any> {
    const href = `${environment.managerProfileUploadSignature}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  cityAdminStaffResetPassword(payload): Observable<any> {
    const href = `${environment.cityAdminStaffResetPassword}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  searchAddress(searchQuery): Observable<any> {
    const href = environment['searchAddress'];
    return this.http.get<any>(href, { params: searchQuery }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  addLicenseFee(payload): Observable<any> {
    const href = `${environment.addLicenseFee}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  updateLicenseFee(payload): Observable<any> {
    const href = `${environment.updateLicenseFee}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  deleteLicenseFee(payload): Observable<any> {
    const href = `${environment.deleteLicenseFee}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  removeOwnerOfficerByAdmin(payload) {
    const href = `${environment.deleteOwnerOfficerAdmin}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  editOwnerOfficerByAdmin(payload) {
    const href = `${environment.editOwnerOfficerAdmin}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  sendAdditionalInfoDocLink(id) {
    const href = `${environment.sendApplicationAdditionalInfoAdmin}`;
    return this.http.get<any>(href, { params: id }).pipe(map(
      (data) => {
        if (data.status === 'success') {
          return data;
        }
      }
    ));
  }
  getPaymentDetail(data: any): Observable<any> {
    const href = environment['getAdminPaymentList'];
    return this.http.get<any>(href, { params: data }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }))
  }

  downloadExportFile(data: any): Observable<any> {
    const href = environment['getExportDataFile'];
    return this.http.get<any>(href, { params: data }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }))
  }

  verifyCSRFToken(tokenQuery) {
    const href = `${environment.verifyCSRFTokenAdmin}`;
    return this.http.get<any>(href, { params: tokenQuery }).pipe(map(
      (data) => {
        if (data.status === 'success') {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(data.response));
          this.userService.userDetails.next(data.response);
          return data.response
        }
      }
    ));
  }
 
  sendOtpProfile(): Observable<any> {
    const href = `${environment.sendOtpProfile}`;
    return this.http.get<any>(href).pipe(map((res: any) => {
      console.log("re",res.status)
      if (res.status == 'success') {
        return res;
      }
    }));
  }
  verifiyOtpProfileadmin(data){
    const href = environment.verifiyOtpProfileadmin;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status === 'success') {
        const storage = localStorage;
        storage.setItem(credentialsKey, JSON.stringify(res.response));
      }
      if (res.status == 'success') {
        return res;
      }
    }));
  }
  emailResendVerificationadmin(data){
    const href = environment.emailResendVerificationadmin;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
}
