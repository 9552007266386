import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, debounce } from 'rxjs/operators';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Login } from '../../shared/models/login.model';
const credentialsKey = 'currentUser';

@Injectable()
export class AuthenticationService {

  public loginDetailsBs = new BehaviorSubject(null);
  public obsLoginDetails = this.loginDetailsBs.asObservable();

  public loggedOutBs = new BehaviorSubject(false);
  public obsLoggedOutBs = this.loggedOutBs.asObservable();

  constructor(private http: HttpClient) {
  }

  login(loginData: Login): Observable<any> {
    const href = `${environment.login}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            const storage = localStorage;
            storage.setItem(credentialsKey, JSON.stringify(data.response));
          }
          return data;
        }
      )
    );
  }

  logout(): Observable<boolean> {
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
    sessionStorage.removeItem('csrfToken')
    return observableOf(true);
  }

  getUserInfo(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials);
  }

  isLogin() {
    if (localStorage.getItem(credentialsKey)) {
      return true;
    }
    return false;

  }

  getToken() {
    const savedCredentials = this.getUser();
    return savedCredentials && savedCredentials['token'];
  }

  getUserRole(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials['role']);
  }

  getUserType() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials['role'];
    } else {
      return false;
    }
  }

  getUser() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    return JSON.parse(savedCredentials);
  }

  setUser(user: any): void {
    localStorage.setItem(credentialsKey, JSON.stringify(user));
  }

  sendPasswordLink(data): Observable<any> {
    const href = environment.sendPasswordLink;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status == 'success') {
        return res.response;
      }
    }));
  }

  resetPassword(formData): Observable<any> {
    const href = environment.resetPassword;
    return this.http.post<any>(href, formData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            return data;
          }
        }
      )
    );
  }

  adminLogin(loginData: Login): Observable<any> {
    const href = `${environment.adminLogin}`;
    return this.http.post<any>(href, loginData).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            const storage = localStorage;
            this.setUser(data.response);
          }
          return data;
        }
      )
    );
  }

  createStaffPassword(payload): Observable<any> {
    const href = environment['createStaffPassword'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  sendPasswordLinkStaff(data): Observable<any> {
    const href = environment.sendPasswordLinkStaff;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status == 'success') {
        return res.response;
      }
    }));
  }

  sendResetPasswordLinkStaff(data): Observable<any> {
    const href = environment.sendResetPasswordLinkStaff;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status == 'success') {
        return res.response;
      }
    }));
  }

  resetStaffPassword(payload): Observable<any> {
    const href = environment.resetStaffPassword;
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getCSRFToken(): Observable<any> {
    const href = environment['getCSRFToken'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  emailLinkVerificationadmin(data){
    const href = environment.emailLinkVerificationadmin;
    return this.http.get<any>(href, {params: data}).pipe(map((data: any) => {
      // if (data.status == 'success') {
        return data;
      // }
    }));
  }
}
